<template>
  <div class="p-datatable">
    <div class="p-datatable-header border-top-none flex flex-row justify-content-between">
      <Breadcrumb :home="home" :model="listCrumbs" class="p-0 flex">
        <template #item="{item}">
          <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
        </template>
      </Breadcrumb>
      <div class="flex flex-column justify-content-center flex-grow-1 align-items-end max-w-full opacity-0">
        <Button></Button>
      </div>
    </div>
  </div>
  <div v-if="!listCandidates.length" class="mt-4">
    Es sind aktuell keine offenen Laufzettel vorhanden.
  </div>
  <div v-else :class="`flex-grow-1 flex flex-column deferred-content${loading ? ' loading' : ''}`">
    <TableComponent :rows="listCandidates" :columns="mapColumns" v-model:selected="selectedCandidates" :key="trigger" @row-clicked="onRowClicked"
                    @selectionChange="onSelectionChange" selection-mode="none" :buttons="buttons" :display-all-columns="false"
                    :fullscreen-table="true" :search="searchValue" :groupRowsBy="'namePartition'" class="laufzettel" :rowClass="rowClass">
    </TableComponent>
  </div>
</template>

<script>
import AuthView from "@/components/AuthView";
import candidatesService from "@/candidates-service";
import TableComponent from "@/components/TableComponent";

export default {
  extends: AuthView,
  name: 'LaufzettelsView',
  components: {
    TableComponent
  },
  data() {
    return {
      requiredUserLevel:0,
      listCandidates:[],
      selectedCandidates:[],
      mapColumns:{
        namePartition:"Partition",
        name:"Name",
        vorname:"Vorname",
        universitaet:"Universität",
        nameSchwerpunkt:"Schwerpunkt",
      },
      buttons:[],
      loading:true,
      searchValue:"",
      trigger:0,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'Laufzettel',url: '/laufzettel'}
      ],
    }
  },
  async created() {
    const isAuth = await this.deferred;
    if (isAuth)
      await this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      this.listCandidates = await this.getCandidates();
      console.log(this.listCandidates)
      this.trigger++;
      this.loading = false;
    },
    async getCandidates() {
      let {data} = await candidatesService.index({idStatus:1});
      return data;
    },
    onSelectionChange(selectedUsers) {
      console.log(selectedUsers,this.selectedUsers);
    },
    async createCandidate() {
      let {data} = await candidatesService.create({name:"Neuer Kandidat",vorname:""});
      this.$router.push(`${this.$route.path.replace(/\/$/,"")}/${data.insertId}/`);
    },
    requestRemoveCandidate(args) {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        acceptIcon:"pi pi-exclamation-triangle text-xl",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Sicher?`,
        header: 'Kandidatin löschen',
        icon: 'pi pi-question-circle',
        accept: async () => {
          try {
            await candidatesService.delete(args.id);
          } catch (err) {
            this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
          }
          this.loadData();
        }
      });
    },
    onRowClicked(args) {
      this.$router.push(`${this.$route.path.replace(/\/$/,"")}/${args.id}`);
    },
    rowClass(data) {
      return data.done ? "is-done" : "";
    }
  }
}
</script>
