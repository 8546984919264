<script>
export default {
  name: "AuthView",
  data() {
    return {
      deferred:null
    }
  },
  async created() {
    this.deferred = new Promise((resolve)=>{
      this.authService.ensureAuth(this.requiredUserLevel||false)
      .then(isAuth=>{
        if (!isAuth)
          this.$router.push("/login");
        resolve(isAuth);
      })
      .catch(err => {
        this.$toast.add({severity:'error', summary: err.messageTitle||'Fehler', detail:err.message||err, life: 3000})
        this.$router.push("/login");
        resolve(false);
      });
    });
  }
};
</script>