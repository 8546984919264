import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import LoginView from "@/components/LoginView";
import HomeView from "@/components/HomeView";
import LaufzettelView from "@/components/LaufzettelView";
import AdminView from "@/components/AdminView";
import UsersView from "@/components/UsersView";
import CandidatesView from "@/components/CandidatesView";
import CandidateView from "@/components/CandidateView";
import LaufzettelsView from "@/components/LaufzettelsView";
import SystemView from "@/components/SystemView";
import StandView from "@/components/StandView";
import UserView from "@/components/UserView";
import CategoriesView from "@/components/CategoriesView";
import SettingsView from "@/components/SettingsView";
import ResetView from "@/components/ResetView";
import ResetRequestView from "@/components/ResetRequestView";

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: HomeView
  },
  {
    path: '/laufzettel',
    component: LaufzettelsView
  },
  {
    path: '/laufzettel/:id',
    component: LaufzettelView,
    props:true
  },
  {
    path: '/admin',
    component: AdminView
  },
  {
    path: '/admin/kandidaten',
    component: CandidatesView
  },
  {
    path: '/admin/kandidaten/:id',
    component: CandidateView,
    props: true
  },
  {
    path: '/admin/stand',
    component: StandView
  },
  {
    path: '/system',
    component: SystemView
  },
  {
    path: '/system/users',
    component: UsersView
  },
  {
    path: '/system/users/:id',
    component: UserView,
    props: true
  },
  {
    path: '/system/struktur',
    component: CategoriesView
  },
  {
    path: '/system/config',
    component: SettingsView
  },
  {
    path: '/Login',
    component: LoginView
  },
  {
    path: '/reset',
    component: ResetRequestView
  },
  {
    path: '/reset/:uuid',
    component: ResetView,
    props: true
  },
/*  {
    path: '/item/:id',
    component: ItemView,
    props:true
  }*/
]

const router = createRouter({
  history: process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory(process.env.BASE_URL),
  routes
})

export default router
