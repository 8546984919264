<template>
  <div class="p-datatable">
    <div class="p-datatable-header border-top-none flex flex-row justify-content-between">
      <Breadcrumb :home="home" :model="listCrumbs" class="p-0 flex">
        <template #item="{item}">
          <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
        </template>
      </Breadcrumb>
    </div>
  </div>
  <div :class="`deferred-content${loading ? ' loading' : ''}`">
    <div class="p-datatable mt-2">
      <div class="p-datatable-header border-top-none flex flex-row align-items-center p-3 justify-content-between">
        <div>Kategorien</div>
      </div>
    </div>
    <DataTable :value="listCategories" responsiveLayout="scroll" :class="'flex flex-column category-table'" edit-mode="cell" :key="trigger">
      <Column v-for="(value,key) in mapColumnsCategories" :field="key" :header="value" :key="key" class="p-cell-editing">
        <template #body="{data,field}">
          <div class="flex flex-row justify-content-between align-items-center">
            <InputText v-model="data[field]" class="flex-grow-1" @change="onChangeCategory(data)"></InputText>
          </div>
        </template>
      </Column>
      <Column bodyStyle="text-align: right" class="column-button">
        <template #body="slotProps">
          <div class="flex flex-row justify-content-end">
            <Button icon="pi pi-trash" :class="'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="requestRemoveCategory(slotProps.data)"/>
          </div>
        </template>
      </Column>
    </DataTable>
    <div class="flex flex-row justify-content-start m-1">
      <Button icon="pi pi-plus" :class="'p-button-rounded button-primary'" @click="createCategory"/>
    </div>
    <div class="p-datatable mt-2">
      <div class="p-datatable-header border-top-none flex flex-row align-items-center p-3 justify-content-between">
        <div>Schwerpunkte</div>
      </div>
    </div>
    <DataTable :value="listSchwerpunkte" responsiveLayout="scroll" :class="'flex flex-column category-table'" edit-mode="cell" :key="trigger">
      <Column v-for="(value,key) in mapColumnsSchwerpunkte" :field="key" :header="value" :key="key" class="p-cell-editing">
        <template #body="{data,field}">
          <div  v-if="key==='idPartition'" class="flex flex-row justify-content-between align-items-center">
            <Dropdown v-model="data[field]" :options="mapEnums['idPartition']" optionLabel="name" optionValue="id" class="flex-grow-1" @change="onChangeSchwerpunkt(data)"></Dropdown>
          </div>
          <div v-else class="flex flex-row justify-content-between align-items-center">
            <InputText v-model="data[field]" class="flex-grow-1" @change="onChangeSchwerpunkt(data)"></InputText>
          </div>
        </template>
      </Column>
      <Column bodyStyle="text-align: right" class="column-button">
        <template #body="slotProps">
          <div class="flex flex-row justify-content-end">
            <Button icon="pi pi-cog" :class="'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="configureSchwerpunkt(slotProps.data)"/>
          </div>
        </template>
      </Column>
      <Column bodyStyle="text-align: right" class="column-button">
        <template #body="slotProps">
          <div class="flex flex-row justify-content-end">
            <Button icon="pi pi-trash" :class="'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="requestRemoveSchwerpunkt(slotProps.data)"/>
          </div>
        </template>
      </Column>
    </DataTable>
    <div class="flex flex-row justify-content-start m-1">
      <Button icon="pi pi-plus" :class="'p-button-rounded button-primary'" @click="createSchwerpunkt"/>
    </div>
  </div>
</template>

<script>
import AuthView from "@/components/AuthView";
import enumsService from "@/enums-service";
import SchwerpunkteDialog from "@/components/SchwerpunkteDialog";

export default {
  extends: AuthView,
  name: 'CategoriesView',
  data() {
    return {
      requiredUserLevel:2,
      listCategories:[],
      listSchwerpunkte:[],
      listX:[],
      mapColumnsCategories:{
        name:"Bezeichnung",
        description:"Beschreibung"
      },
      mapColumnsSchwerpunkte:{
        name:"Bezeichnung",
        idPartition:"Stufe"
      },
      buttons:[{
        icon:"pi pi-trash",
        onClick:(args)=>{
          this.requestRemoveUser(args);
        }
      },{
        icon:"pi pi-arrow-right",
        onClick:(args)=>{
          this.$router.push(`${this.$route.path.replace(/\/$/,"")}/${args.id}`);
        }
      }],
      loading:true,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'System',url: '/system'},
        {label: 'Struktur',url: '/system/struktur'}
      ],
      searchValue:"",
      trigger:0,
      listEnumKeys: ["kategorien","schwerpunkte","partitions"],
      mapEnums: {},
      mapEnumColumnKey: {
        kategorien:"idKategorie",
        schwerpunkte:"idSchwerpunkt",
        partitions:"idPartition"
      },
    }
  },
  async created() {
    await this.deferred;
    await this.loadData();
    this.trigger++;
    this.loading = false;
  },
  methods: {
    async loadData() {
      this.loadEnums();
      this.listCategories = (await enumsService.index("kategorien")).data||[];
      this.listSchwerpunkte = (await enumsService.index("schwerpunkte")).data||[];
    },
    async loadEnums() {
      await Promise.all(this.listEnumKeys.map(async en=>{
        if (this.mapEnumColumnKey[en]) {
          let key = this.mapEnumColumnKey[en];
          this.mapEnums[key] = (await enumsService.index(en)).data||[];
        }
      }));
    },
    async createCategory() {
      await enumsService.create("kategorien",{name:"Neue Kategorie"});
      this.loadData();
    },
    requestRemoveEnum(key,args) {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        acceptIcon:"pi pi-exclamation-triangle text-xl",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Sicher?`,
        header: 'Eintrag löschen',
        icon: 'pi pi-question-circle',
        accept: async () => {
          try {
            await enumsService.delete(key,args.id);
          } catch (err) {
            this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
          }
          this.loadData();
        }
      });
    },
    requestRemoveCategory(args) {
      return this.requestRemoveEnum("kategorien",args);
    },
    requestRemoveSchwerpunkt(args) {
      return this.requestRemoveEnum("schwerpunkte",args);
    },
    async onChangeCategory(data) {
      enumsService.update("kategorien",data.id,data);
      this.loadEnums();
    },
    async createSchwerpunkt() {
      await enumsService.create("schwerpunkte",{name:"Neuer Schwerpunkt"});
      this.loadData();
    },
    onChangeSchwerpunkt(data) {
      enumsService.update("schwerpunkte",data.id,data);
    },
    configureSchwerpunkt(data) {
      this.$dialog.open(SchwerpunkteDialog, {
        props: {
          header: `Kategorien für '${data.name}'`,
          modal: true,
          style: {
            width: '50vw',
          },
          breakpoints:{
            '960px': '80vw',
            '640px': '90vw',
            '460px': '100vw'
          },
          dismissableMask:true
        },
        data: {
          idSchwerpunkt:data.id,
          mapEnums:this.mapEnums
        }
      });
    },
  }
}
</script>
