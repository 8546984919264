import CrudService from "./crud-service";
import http from "@/http-auth";

class CandidatesService extends CrudService {
  index(data,config) {
    return http.post(`/${this.apiName}`,data,config);
  }
  create(data, config) {
    return http.post(`/${this.apiName}/create`, data, config);
  }
}
export default new CandidatesService("candidates");
