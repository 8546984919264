<template>
  <DataTable :value="list" responsiveLayout="scroll" :class="'flex flex-column category-table'" edit-mode="cell" :key="trigger">
    <Column v-for="(value,key) in mapColumns" :field="key" :header="value" :key="key" class="p-cell-editing">
      <template #body="{data,field}">
        <div class="flex flex-row justify-content-between align-items-center">
          <Dropdown v-model="data[field]" :options="mapEnums[field]" optionLabel="name" optionValue="id" class="flex-grow-1" @change="onChangeEntry(data)"></Dropdown>
        </div>
      </template>
    </Column>
    <Column bodyStyle="text-align: right" class="column-button">
      <template #body="slotProps">
        <div class="flex flex-row justify-content-end">
          <Button icon="pi pi-trash" :class="'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="requestRemoveEntry(slotProps.data)"/>
        </div>
      </template>
    </Column>
  </DataTable>
  <div class="flex flex-row justify-content-start m-1">
    <Button icon="pi pi-plus" :class="'p-button-rounded button-primary'" @click="createEntry"/>
  </div>
</template>

<script>

import enumsService from "@/enums-service";

export default {
  inject: ['dialogRef'],
  name: "SchwerpunkteDialog",
  data() {
    return {
      idSchwerpunkt:0,
      mapEnums:{},
      list:[],
      trigger:0,
      mapColumns:{
        idKategorie:"Kategorie",
      }
    }
  },
  async created() {
    let params = this.dialogRef.data;
    if (params) {
      this.idSchwerpunkt = params.idSchwerpunkt;
      this.mapEnums = params.mapEnums;
    }
    this.loadData();
  },
  methods: {
    async loadData() {
      this.list = ((await enumsService.index("schwerpunkteXkategorien")).data||[]).filter(item=>item.idSchwerpunkt===this.idSchwerpunkt);
    },
    async onChangeEntry(data) {
      try {
        await enumsService.update("schwerpunkteXkategorien",data.id,data);
      } catch (err) {
        this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000});
        this.loadData();
      }
    },
    async createEntry() {
      try {
        await enumsService.create("schwerpunkteXkategorien",{idSchwerpunkt:this.idSchwerpunkt,idKategorie:0});
        this.loadData();
      } catch (err) {
        this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
      }
    },
    requestRemoveEntry(args) {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        acceptIcon:"pi pi-exclamation-triangle text-xl",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Sicher?`,
        header: 'Eintrag löschen',
        icon: 'pi pi-question-circle',
        accept: async () => {
          try {
            await enumsService.delete("schwerpunkteXkategorien",args.id);
          } catch (err) {
            this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
          }
          this.loadData();
        }
      });
    }
  }
}
</script>

<style scoped>

</style>