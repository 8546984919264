import http from "./http-common";
import AuthService from "@/auth-service";

class HttpAuth {
  authService;
  constructor() {
    this.authService = new AuthService();
  }
  async get(url,config) {
    return await this.authService.ensure(async (localAuth) => {
      return await http.get(url,{
        headers:{
          'Authorization' : 'Bearer '+localAuth.accessToken,
        },
        ...config||{}})
      }
    );
  }
  async post(url,data,config) {
    return await this.authService.ensure(async (localAuth)=>{
      return await http.post(url,data,{
        ...config||{},
        headers:{
          ...(config||{}).headers||{},
          'Authorization' : 'Bearer '+localAuth.accessToken
        }
      })
    });
  }
  async put(url,data,config) {
    return await this.authService.ensure(async (localAuth)=>{
      return await http.put(url,data,{
        headers:{
          'Authorization' : 'Bearer '+localAuth.accessToken,
        },
        ...config||{}});
    });
  }
  async delete(url,config) {
    return await this.authService.ensure(async (localAuth)=>{
      return await http.delete(url,{
      headers:{
        'Authorization' : 'Bearer '+localAuth.accessToken,
      },
      ...config||{}});
    });
  }
}
export default new HttpAuth();