<template>
  <div class="p-datatable">
    <div class="p-datatable-header border-top-none flex flex-row justify-content-between">
      <Breadcrumb :home="home" :model="listCrumbs" class="p-0 flex">
        <template #item="{item}">
          <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
        </template>
      </Breadcrumb>
      <div class="flex flex-column justify-content-center flex-grow-1 align-items-end max-w-full opacity-0">
        <Button></Button>
      </div>
    </div>
  </div>
  <div :class="`flex-grow-1 flex flex-column deferred-content${loading ? ' loading' : ''}`">
    <TableComponent :rows="listStats" :columns="mapColumns" :key="trigger"
                    @selectionChange="onSelectionChange" selection-mode="none" :buttons="buttons" :display-all-columns="false" :loading="loading"
                    :search="searchValue" :groupRowsBy="'namePartition'" class="laufzettel" :rowClass="rowClass">
    </TableComponent>
    <div class="flex flex-column align-items-start">
      <Button class="p-button-primary mt-2 mx-1" :icon="senderIcon['mail-new']" label="Neue Dossiers" @click="requestSend('mail-new')"></Button>
      <Button class="p-button-warning mt-2 mx-1" :icon="senderIcon['mail-due']" label="Dossiers fällig" @click="requestSend('mail-due')"></Button>
    </div>
  </div>
</template>

<script>
import AuthView from "@/components/AuthView";
import laufzettelService from "@/laufzettel-service";
import TableComponent from "@/components/TableComponent";
import MailDialog from "@/components/MailDialog";
import mailService from "@/mail-service";

export default {
  extends: AuthView,
  name: 'StandView',
  components: {
    TableComponent
  },
  data() {
    return {
      requiredUserLevel:2,
      listStats:[],
      mapColumns:{
        username:"Benutzername",
        progress:false
      },
      buttons:[],
      loading:true,
      searchValue:"",
      trigger:0,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'Admin',url: '/admin'},
        {label: 'Stand',url: '/admin/stand'}
      ],
      senderIcon:{

      }
    }
  },
  async created() {
    await this.deferred;
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.updateSenderIcon("mail-new");
      this.updateSenderIcon("mail-due");
      this.loading = true;
      this.listStats = await this.getStats();
      this.trigger++;
      this.loading = false;
    },
    async getStats() {
      let {data} = await laufzettelService.stats();
      data = data.map(item=>{
        return {
          username:item.username,
          email:item.email,
          progress:`${item.numDone}/${item.n}`,
          done:parseInt(item.numDone)>=parseInt(item.n)
        }
      })
      return data;
    },
    onSelectionChange(selectedUsers) {
      console.log(selectedUsers,this.selectedUsers);
    },
    onRowClicked(args) {
      this.$router.push(`${this.$route.path.replace(/\/$/,"")}/${args.id}`);
    },
    rowClass(data) {
      return data.done ? "is-done" : "";
    },
    updateSenderIcon(which,sending=false) {
      this.senderIcon[which] = sending ? "pi pi-sync pi-spin" : "pi pi-envelope";
    },
    applyThrottling() {
      return new Promise(resolve=>setTimeout(()=>resolve(),3000));
    },
    async sendMails({recipients,content,subject}) {
      let i=0;
      while (i<recipients.length) {
        const {email} = recipients[i];
        try {
          let data = (await mailService.send({to:[email],html:content,subject})).data||{};
          await this.applyThrottling();
          this.$toast.add({severity:'success', summary: email, detail:data.success, life: 3000})
        }
        catch (err) {
          const {status,error} = err.response && err.response.data;
          this.$toast.add({severity:'error', summary: `Fehler ${status}`, detail:error, life: 3000})
        }
        i++;
      }
    },
    requestSend(template="mail-new") {
      this.$dialog.open(MailDialog, {
        props: {
          header: 'E-Mail senden',
          modal: true,
          style: {
            width: '50vw',
          },
          breakpoints:{
            '960px': '80vw',
            '640px': '90vw',
            '460px': '100vw'
          },
        },
        data: {
          template,
          recipients:template==='mail-due' ? this.listStats.filter(item=>!item.done) : [...this.listStats]
        },
        onClose: async (options) => {
          const data = options.data;
          if (data) {
            this.updateSenderIcon(template,true)
            await this.sendMails(data);
            this.updateSenderIcon(template)
          }
        }
      });
    },
  }
}
</script>
