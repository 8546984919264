<template>
  <div class="p-fluid grid formgrid">
    <div class="field col-12">
      <Editor ref="editor" v-model="val" editorStyle="height: 320px">
        <template #toolbar>
        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
        </span>
        </template>
      </Editor>
    </div>
  </div>
  <div class="flex flex-row mt-4 justify-content-end w-full">
    <Button label="Abbrechen" icon="pi pi-times" @click="closeDialog(false)" class="p-button-text mr-2"/>
    <Button label="Speichern" icon="pi pi-save" @click="closeDialog(true)" autofocus />
  </div>
</template>

<script>

export default {
  inject: ['dialogRef'],
  name: "EditorDialog",
  data() {
    return {
      val:"",
    }
  },
  async created() {
    let params = this.dialogRef.data;
    if (params) {
      this.val = params.val;
    }
  },
  methods: {
    closeDialog(ok) {
      this.dialogRef.close(ok ? {
        val:this.val,
      } : null);
    },
  }
}
</script>

<style scoped>

</style>