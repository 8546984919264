import CrudService from "./crud-service";
import http from "@/http-auth";

class LaufzettelService extends CrudService {
  update(which, data, config) {
    return http.post(`/${this.apiName}/${which}`, data, config);
  }
  stats(which, data, config) {
    return http.post(`/${this.apiName}/stats`, data, config);
  }
}
export default new LaufzettelService("laufzettel");