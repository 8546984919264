<template>
  <draggable
      v-model="files"
      @start="drag=true"
      @end="drag=false"
      item-key="filename">
    <template #item="{element}">
      <div class="mb-2 px-2 py-3 cursor-pointer flex flex-row bg-primary-50 justify-content-between align-items-center">
        <div class="ml-2">{{element.label}}</div>
        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text p-button-lg" @click="requestExcludeDocument(element)"/>
      </div>
    </template>
  </draggable>
  <div class="flex flex-column">
    <label for="targetFilename" class="font-bold mt-2">Ziel-Dateiname</label>
    <InputText id="targetFilename" type="text" v-model="targetFilename"></InputText>
  </div>
  <div class="flex flex-row mt-4 justify-content-end w-full">
    <Button label="Abbrechen" icon="pi pi-times" @click="closeDialog(false)" class="p-button-text"/>
    <Button :disabled="!files.length" label="Ok" icon="pi pi-check" @click="closeDialog(true)" autofocus />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
export default {
  inject: ['dialogRef'],
  components: {
    draggable
  },
  name: "MergeDocumentsDialog",
  data() {
    return {
      files:[],
      targetFilename:"",
      drag:false
    }
  },
  created() {
    let params = this.dialogRef.data;
    if (params) {
      this.files = params.files;
      this.targetFilename = params.targetFilename;
    }
  },
  methods: {
    closeDialog(ok) {
      this.dialogRef.close(ok ? {
        files:this.files,
        targetFilename:this.targetFilename
      } : null);
    },
    requestExcludeDocument(file) {
      this.$confirm.require({
        acceptClass:"p-button-primary",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Sicher?`,
        header: 'Datei ausschliessen',
        icon: 'pi pi-question-circle',
        accept: async () => {
          let i=0;
          while (i<this.files.length && this.files[i].filename!==file.filename)
            i++;
          if (i<this.files.length)
            this.files.splice(i,1);
        }
      });
    }
  }
}
</script>

<style scoped>

</style>