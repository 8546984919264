<template>
  <div class="p-datatable">
    <div class="p-datatable-header border-top-none flex flex-row justify-content-between">
      <Breadcrumb :home="home" :model="listCrumbs" class="p-0 flex">
        <template #item="{item}">
          <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
        </template>
      </Breadcrumb>
      <div class="flex flex-column justify-content-center flex-grow-1 align-items-end max-w-full">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="searchValue" placeholder="Suche" @keyup.enter="trigger++" class="search"/>
          <i class="pi pi-times right-0 mr-2 cursor-pointer" v-if="searchValue" @click="searchValue=''; trigger++"/>
        </span>
      </div>
      <Button label="Neuer&nbsp;Kandidat" icon="pi pi-plus" class="p-button-primary ml-1" @click="createCandidate"></Button>
    </div>
  </div>
  <div :class="`flex-grow-1 flex flex-column deferred-content${loading ? ' loading' : ''}`">
    <TableComponent :rows="listCandidates" :columns="mapColumns" v-model:selected="selectedCandidates" :key="trigger"
        @selectionChange="onSelectionChange" selection-mode="none" :buttons="buttons" :display-all-columns="false" :loading="loading"
        :fullscreen-table="true" :search="searchValue" :pagination="true">
    </TableComponent>
  </div>
</template>

<script>
import AuthView from "@/components/AuthView";
import candidatesService from "@/candidates-service";
import TableComponent from "@/components/TableComponent";

export default {
  extends: AuthView,
  name: 'CandidatesView',
  components: {
    TableComponent
  },
  data() {
    return {
      requiredUserLevel:1,
      listCandidates:[],
      selectedCandidates:[],
      mapColumns:{
        id:"id",
        name:"Name",
        vorname:"Vorname",
        universitaet:"Universität",
        nameSchwerpunkt:"Schwerpunkt",
        nameStatus:"Status"
      },
      buttons:[{
        icon:"pi pi-trash",
        onClick:(args)=>{
          this.requestRemoveCandidate(args);
        }
      },{
        icon:"pi pi-arrow-right",
        onClick:(args)=>{
          this.$router.push(`${this.$route.path.replace(/\/$/,"")}/${args.id}`);
        }
      }],
      loading:true,
      searchValue:"",
      trigger:0,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'Admin',url: '/admin'},
        {label: 'Kandidaten',url: '/admin/kandidaten'}
      ]
    }
  },
  async created() {
    await this.deferred;
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      this.listCandidates = await this.getCandidates();
      this.loading = false;
      this.trigger++;
    },
    async getCandidates() {
      let {data} = await candidatesService.index();
      return data;
    },
    onSelectionChange(selectedUsers) {
      console.log(selectedUsers,this.selectedUsers);
    },
    async createCandidate() {
      let {data} = await candidatesService.create({name:"Neuer Kandidat",vorname:""});
      this.$router.push(`${this.$route.path.replace(/\/$/,"")}/${data.insertId}/`);
    },
    requestRemoveCandidate(args) {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        acceptIcon:"pi pi-exclamation-triangle text-xl",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Sicher?`,
        header: 'Kandidatin löschen',
        icon: 'pi pi-question-circle',
        accept: async () => {
          try {
            await candidatesService.delete(args.id);
          } catch (err) {
            this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
          }
          this.loadData();
        }
      });
    }
  }
}
</script>
