<template>
  <div class="p-datatable">
    <div class="p-datatable-header border-top-none flex flex-row justify-content-between p-button-success pr-2">
      <Breadcrumb :home="home" :model="listCrumbs" class="p-0 flex">
        <template #item="{item}">
          <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
        </template>
      </Breadcrumb>
      <div class="flex flex-column justify-content-center flex-grow-1 align-items-end max-w-full">
        <Button :label="getStatusLabel" :icon="data.idStatus===1 ? 'pi pi-clock' : (data.idStatus===2 ? 'pi pi-check' : 'pi pi-question-circle')" :class="data.idStatus===2 ? 'p-button-success bg-green-500' : ''" @click="requestToggleStatus"></Button>
      </div>
    </div>
  </div>
  <div class="flex flex-column mt-2">
    <div v-for="(value,name) in data" v-bind:key="name" >
      <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center p-2" v-if="mapColumns[name]!==null">
        <label :for="name" class="w-2 flex-shrink-0">{{mapColumns[name]||name}}</label>
        <Dropdown v-if="mapColumnEnumKey[name]" v-model="data[name]" :options="mapEnum[mapColumnEnumKey[name]]" optionLabel="name" optionValue="id" placeholder="Wählen" @focus="saveState" @change="name==='idStatus' ? requestSetStatus() : onChangeData()" />
        <InputText v-else :id="name" type="text" v-model="data[name]" class="flex-grow-1" @input="onChangeData" />
      </div>
    </div>
    <div class="flex flex-column lg:flex-row justify-content-start p-2 document-viewer">
      <label class="w-2 lg:pt-2 flex-shrink-0">Dokumente</label>
      <div :class="'flex flex-row align-items-start flex-grow-1 justify-content-'+(listFiles.length ? 'between' : 'start')">
        <div class="flex flex-column">
          <div class="flex flex-row mb-1 align-items-center" v-for="file in listFiles" :key="file" >
            <a @click.prevent="download(file)" class="cursor-pointer text-left hover:text-primary">{{file.label}}</a>
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text p-button-lg" @click="requestRemoveFile(file)"/>
          </div>
        </div>
        <div class="flex flex-column">
          <FileUpload name="files[]" multiple :customUpload="true" @uploader="handleUpload" :auto="true" :showUploadButton="false" :showCancelButton="false" chooseLabel="Upload" :chooseIcon="uploadIcon">
            <template #empty></template>
          </FileUpload>
          <Button label="Merge" v-if="showMergeButton" :icon="mergeIcon" class="p-button-secondary mt-1" @click="showMergeDocumentsDialog"></Button>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-column lg:flex-row justify-content-start p-2 html-editor">
    <label class="w-2 lg:pt-2 flex-shrink-0">Bemerkungen</label>
    <Editor v-model="data.comment" editorStyle="height: 320px" class="flex-grow-1" @text-change="onChangeData">
      <template #toolbar>
        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class=""></button>
        </span>
      </template>
    </Editor>
  </div>
</template>

<script>
import AuthView from "@/components/AuthView";
import candidatesService from "@/candidates-service";
import enumsService from "@/enums-service";
import documentsService from "@/documents-service";
import MergeDocumentsDialog from "@/components/MergeDocumentsDialog";

export default {
  extends: AuthView,
  name: 'CandidateView',
  props: {
    id:Number
  },
  data() {
    return {
      requiredUserLevel:1,
      data: {},
      loading:true,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'Admin',url: '/admin'},
        {label: 'Kandidaten',url: '/admin/kandidaten'},
        {label: ''}
      ],
      mapColumns:{
        id:null,
        time:null,
        idStatus:"Status",
        idSchwerpunkt:"Schwerpunkt",
        name:"Name",
        vorname:"Vorname",
        universitaet: "Universität",
        comment:null,
        guid:null
      },
      listEnumKeys: ["status","schwerpunkte"],
      mapEnum: {},
      mapColumnEnumKey: {
        idStatus:"status",
        idSchwerpunkt:"schwerpunkte"
      },
      savedState:{},
      listFiles:[],
      uploadIcon:"pi pi-upload",
      mergeIcon:"pi pi-database"
    }
  },
  async created() {
    await this.deferred;
    await this.loadData();
    await this.loadEnums();
    await this.loadFilesList();
    this.listCrumbs[this.listCrumbs.length-1].label = this.data ? `${this.data.vorname} ${this.data.name}` : "";
    this.loading = false;
  },
  methods: {
    async loadData() {
      let {data} = await candidatesService.get(this.id);
      this.data = data;
    },
    async loadEnums() {
      await Promise.all(this.listEnumKeys.map(async key=>{
        this.mapEnum[key] = (await enumsService.index(key)).data||[];
      }));
      this.mapEnum["status"].unshift({id:0,name:"Kein Status"});
    },
    async onChangeData() {
      await candidatesService.update(this.id,this.data);
    },
    requestSetStatus(targetStatus=this.data.idStatus) {
      let mapAction = {
        0:"unterbrechen",
        1:"eröffnen",
        2:"abschliessen"
      }
      this.$confirm.require({
        acceptClass:"p-button-danger",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Möchten Sie die Datenerfassung für dieses Dossier ${mapAction[targetStatus]}?`,
        header: 'Statusänderung',
        icon: 'pi pi-question-circle',
        accept: async () => {
          this.data.idStatus = targetStatus
          await this.onChangeData();
          await this.loadData();
        },
        reject: async () => {
          this.data.idStatus = this.savedState.idStatus;
          await this.loadData();
        }
      });
    },
    requestToggleStatus() {
      return this.requestSetStatus(this.data.idStatus===1 ? 2 : 1)
    },
    saveState() {
      this.savedState = {...this.data};
    },
    async loadFilesList() {
      this.listFiles = (await documentsService.index(this.data.guid)).data || [];
    },
    async download({ filename, label }) {
      let response = await documentsService.get(this.data.guid,filename);
      let blobUrl = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = label;
      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );
      document.body.removeChild(link);
    },
    async remove({ filename }) {
      return await documentsService.delete(this.data.guid,filename);
    },
    async requestRemoveFile(file) {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Möchten Sie die Datei ${file.label} löschen?`,
        header: 'Datei löschen',
        icon: 'pi pi-question-circle',
        accept: async () => {
          try {
            await this.remove(file);
            this.loadFilesList();
          }
          catch (err) {
            this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
          }
        },
      });
    },
    async handleUpload(e) {
      this.selectedSheets = [];
      this.updateUploadIcon(true);
      try {
        await documentsService.upload(this.data.guid,e.files);
        this.loadFilesList();
      }
      catch (err) {
        this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
      }
      this.updateUploadIcon(false);
    },
    updateUploadIcon(uploading=false) {
      this.uploadIcon = uploading ? "pi pi-sync pi-spin" : "pi pi-upload";
    },
    updateMergeIcon(merging=false) {
      this.mergeIcon = merging ? "pi pi-sync pi-spin" : "pi pi-database";
    },
    async mergeDocuments({files,targetFilename}) {
      this.updateMergeIcon(true);
      try {
        await documentsService.merge(this.data.guid,files,targetFilename);
      } catch (err) {
        this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
      }
      this.loadFilesList();
      this.updateMergeIcon(false);
    },
    showMergeDocumentsDialog() {
      this.$dialog.open(MergeDocumentsDialog, {
        props: {
          header: 'Dokumente zusammenführen',
          modal: true,
          style: {
            width: '50vw',
          },
          breakpoints:{
            '960px': '80vw',
            '640px': '90vw',
            '460px': '100vw'
          }
        },
        data: {
          files:[...this.listFiles],
          targetFilename:this.listFiles.length ? this.listFiles[0].filename : ""
        },
        onClose: (options) => {
          const data = options.data;
          if (data) {
            this.mergeDocuments(data)
          }
        }
      });
    },
  },
  computed: {
    getStatusLabel() {
      let map = {};
      (this.mapEnum['status']||[]).forEach(item=>map[item.id]=item.name);
      return map[this.data.idStatus||0] || "Kein Status";
    },
    showMergeButton() {
      return this.listFiles.reduce((a,b)=>a+(b.filename.match(/\.pdf$/i) ? 1 : 0),0)>1;
    }
  }
}
</script>
