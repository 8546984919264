import http from "./http-auth";

class DocumentsService {
  constructor(apiName="documents") {
    this.apiName=apiName;
  }
  index(key) {
    return http.get(`/${this.apiName}/${key}`);
  }
  get(key,file) {
    return http.get(`/${this.apiName}/${key}/${file}`,{responseType:"blob"});
  }
  delete(key,file) {
    return http.delete(`/${this.apiName}/${key}/${file}`);
  }
  upload(record,files) {
    let formData = new FormData();
    formData.append("record",record);
    files.forEach(file=>{
      formData.append("files",file,encodeURIComponent(file.name))
    });
    return http.post(`/${this.apiName}/upload`,formData,{
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }
  merge(record,files,targetFilename) {
    return http.post(`/${this.apiName}/merge`, {record,files,targetFilename});
  }
}
export default new DocumentsService();
