<template>
  <DataTable :value="rows" responsiveLayout="scroll" :class="'flex flex-column'" edit-mode="cell" :key="trigger">
    <Column v-for="col of listColumns" :field="col.field" :header="col.header" :key="col.field" :hidden="col.hidden" class="p-cell-editing">
      <template #body="{data,field}" v-if="col.field==='_'+userId && !done">
        <div class="flex flex-row justify-content-between align-items-center">
          <Dropdown v-model="data[field].idBescheid" :options="mapEnumsList['idBescheid']" optionLabel="name" optionValue="id" class="flex-grow-1" @change="onChangeBescheid($event,data.idKategorie)"></Dropdown>
          <Button :icon="'pi pi-comment'+(data[field].kommentar ? ' text-primary' : '')" :class="'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="requestEditKommentar(data[field].kommentar,data.idKategorie)"/>
        </div>
      </template>
      <template #body="{data}" v-else-if="col.field==='idKategorie'">
        <div class="flex flex-row align-items-center">
          <div class="white-space-nowrap">{{(mapEnums['idKategorie'][data.idKategorie]||{}).name||""}}</div>&nbsp;
          <i v-if="(mapEnums['idKategorie'][data.idKategorie]||{}).description" class="pi pi-info-circle text-blue-500 text-lg" v-tooltip.focus="(mapEnums['idKategorie'][data.idKategorie]||{}).description||''" tabindex="0"></i>
        </div>
      </template>
      <template #body="{data,field}" v-else>
        <div class="flex flex-row justify-content-between align-items-center">
          <div>{{(mapEnums['idBescheid'][data[field].idBescheid]||{}).name||""}}</div>
          <i v-if="data[field].kommentar" class="pi pi-comment text-primary text-xl" v-tooltip="{value:data[field].kommentar, escape:true}"></i>
        </div>
      </template>
    </Column>
  </DataTable>
</template>
<script>

import EditorDialog from "@/components/EditorDialog";
import Tooltip from 'primevue/tooltip';

export default {
  name: "LaufzettelMatrixComponent",
  emits:["updateBescheid", "updateKommentar"],
  data() {
    return {
      selectedRows:null,
      mapColumns:{
        idKategorie:"Kategorie"
      },
      listColumns:[],
      laufzettelData:[],
      mapEnums:{},
      mapEnumsList:{},
      rows:[],
      trigger:0,
      userId:0,
    }
  },
  directives: {
    'tooltip': Tooltip
  },
  props: {
    data:Array,
    enums:Object,
    done:Boolean
  },
  setup(props) {
    return {props}
  },
  created() {
    this.laufzettelData = this.props.data;
    this.mapEnums = this.props.enums;
    this.userId = (this.authService.getLocalProfile()||{}).id;
    this.mapEnumsList = {};
    for (let key in this.mapEnums) {
      let list = [];
      for (let k in this.mapEnums[key]) {
        list.push({id:parseInt(k),name:this.mapEnums[key][k]?.name});
      }
      this.mapEnumsList[key] = list;
    }
    this.buildLaufzettel();
    this.listColumns = this.getColumns(this.rows);
  },
  methods:{
    async buildLaufzettel() {
      let data = this.laufzettelData;
      let rows=[]
      let i=0;
      while (i<data.length) {
        let cols = {};
        let first = data[i];
        cols["idKategorie"] = first["idKategorie"];
        do {
          let { idBescheid, kommentar, idUser, username } = data[i];
          cols[`_${idUser}`] = { idBescheid, kommentar };
          this.mapColumns[`_${idUser}`] = username;
        } while (++i<data.length && data[i].idKategorie===first.idKategorie)
        rows.push(cols);
      }
      this.rows = rows;
      this.trigger++;
    },
    getColumns(data,mapColumns=this.mapColumns) {
      let columns = [];
      if (this.props.displayAllColumns) {
        for (let key in data[0]) {
            columns.push({field:key,header:mapColumns[key]||key,hidden:mapColumns[key]===null,sortable:true});
        }
      }
      else {
        for (let key in mapColumns) {
          columns.push({field:key,header:mapColumns[key]||key,hidden:mapColumns[key]===null,sortable:true});
        }
      }
      return columns;
    },
    onChangeBescheid(e,idKategorie) {
      this.$emit("updateBescheid",{value:e.value||0,idKategorie});
    },
    onChangeKommentar(kommentar,idKategorie) {
      this.$emit("updateKommentar",{value:kommentar||"",idKategorie});
    },
    requestEditKommentar(val, idKategorie) {
      this.$dialog.open(EditorDialog, {
        props: {
          header: 'Kommentar',
          modal: true,
          style: {
            width: '50vw',
          },
          breakpoints:{
            '960px': '80vw',
            '640px': '90vw',
            '460px': '100vw'
          },
        },

        data: {
          val,
        },
        onClose: (options) => {
          const data = options.data;
          if (data) {
            this.onChangeKommentar(data.val,idKategorie);
          }
        }
      });
    }
  }
};
</script>