<template>
  <div class="p-datatable">
    <div class="p-datatable-header border-top-none flex flex-row justify-content-between p-button-success pr-2">
      <Breadcrumb :home="home" :model="listCrumbs" class="p-0 flex">
        <template #item="{item}">
          <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
        </template>
      </Breadcrumb>
      <div class="flex flex-column justify-content-center flex-grow-1 align-items-end max-w-full">
        <Button @click="requestSetDone(!done)" :label="done ? 'Bearbeiten' : 'Abschliessen'" :icon="done ? 'pi pi-pencil' : 'pi pi-check'" :class="done ? 'p-button-primary' : 'p-button-success bg-green-500'"></Button>
      </div>
    </div>
  </div>
  <div :class="`deferred-content${loading ? ' loading' : ''}`">
    <div class="flex flex-column mt-2 laufzettel-view">
      <div v-for="(value,name) in data" v-bind:key="name" >
        <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center p-2" v-if="mapColumns[name]!==null">
          <label :for="name" class="w-2 flex-shrink-0">{{mapColumns[name]||name}}</label>
          <div class="flex-grow-1">{{mapEnum[name] ? (mapEnum[name][value]|| {}).name||"" : data[name]}}</div>
        </div>
      </div>
      <div class="flex flex-column lg:flex-row justify-content-start p-2 document-viewer">
        <label class="w-2 flex-shrink-0">Dokumente</label>
        <div class="flex flex-column align-items-start">
          <div class="flex flex-row mb-1 align-items-center" v-for="file in listFiles" :key="file">
            <a @click.prevent="download(file)" class="cursor-pointer text-left hover:text-primary"><i :class="file.downloading ? 'pi pi-sync pi-spin' : 'pi pi-file-pdf'"></i> {{file.label}}</a>
          </div>
          <div v-if="!listFiles.length" class="">[Keine Dokumente vorhanden]</div>
        </div>
      </div>
    </div>
    <div class="flex flex-column lg:flex-row justify-content-start p-2 lg:py-0 html-editor" v-if="(data.comment||'').replace(/\s+/,'').length">
      <label class="w-2 lg:pt-2 flex-shrink-0">Bemerkungen</label>
      <div v-html="data.comment" class="lg:my-2 flex-grow-1 html-view xl:flex-grow-0 xl:w-6 line-height-2" @click="onClickComment($event)"></div>
    </div>
    <LaufzettelMatrixComponent :data="laufzettelData" :enums="mapEnum" :key="trigger" @updateBescheid="onUpdateBescheid" @updateKommentar="onUpdateKommentar" class="laufzettel-matrix px-2 mb-3 mt-2" :done="done">
    </LaufzettelMatrixComponent>
    <div v-for="item in listKommentare" :key="item.id" class="flex flex-column kommentar-liste">
      <div class="flex flex-column lg:flex-row justify-content-start p-2 lg:py-0 html-editor">
        <label class="w-full lg:w-2 lg:pt-2 flex-shrink-0"><i class="pi pi-caret-right"></i> {{item.username}}, {{(mapEnum["idKategorie"][item.idKategorie]||{}).name}}:</label>
        <div v-html="item.kommentar" class="lg:my-2 flex-grow-1 html-view kommentar xl:flex-grow-0 xl:w-6 line-height-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthView from "@/components/AuthView";
import candidatesService from "@/candidates-service";
import documentsService from "@/documents-service";
import enumsService from "@/enums-service";
import laufzettelService from "@/laufzettel-service";
import LaufzettelMatrixComponent from "@/components/LaufzettelMatrixComponent";

export default {
  extends: AuthView,
  name: 'LaufzettelView',
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    id: {
      types:[Number, String]
    }
  },
  components: {
    LaufzettelMatrixComponent
  },
  data() {
    return {
      requiredUserLevel:0,
      data: {},
      loading:true,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'Laufzettel',url: '/laufzettel'},
        {label: ''}
      ],
      mapColumns:{
        id:null,
        time:null,
        idStatus:null,
        idSchwerpunkt:"Bewerbung für",
        name:"Name",
        vorname:"Vorname",
        universitaet: "Vorbildung",
        comment:null,
        guid:null
      },
      mapKeyToEnum: {
        idSchwerpunkt:"schwerpunkte",
        idKategorie:"kategorien",
        idBescheid:"bescheide"
      },
      mapEnum: {},
      savedState:{},
      listFiles:[],
      uploadIcon:"pi pi-upload",
      mergeIcon:"pi pi-database",
      laufzettelData:[],
      mapMatrixColumns:{
        idKategorie:"Kategorie"
      },
      trigger:0,
      done:false,
      listKommentare:[]
    }
  },
  async created() {
    await this.deferred;
    await this.loadEnums();
    await this.loadData();
    await this.loadFilesList();
    await this.loadLaufzettelData();
    this.listCrumbs[this.listCrumbs.length-1].label = this.data ? `${this.data.vorname} ${this.data.name}` : "";
    this.loading = false;
  },
  methods: {
    async loadLaufzettelData() {
      let {data} = (await laufzettelService.get(this.id))||{};
      this.laufzettelData = data.matrix;
      this.listKommentare = this.laufzettelData.filter(item=>item.kommentar).map(item=>{
        return {
          ...item,
          kommentar:!item.kommentar.match(/<.+>/) ? item.kommentar.replace(/^\s+/g,"").replace(/\n/g,"<br>") : item.kommentar
        }
      });
      this.done = data.done;
      this.trigger++;
    },
    async loadData() {
      let {data} = await candidatesService.get(this.id);
      this.data = data;
    },
    async loadFilesList() {
      this.listFiles = (await documentsService.index(this.data.guid)).data || [];
    },
    setDownloading(filename,downloading=true) {
      let i=0;
      while (i<this.listFiles.length && this.listFiles[i].filename!==filename)
        i++;
      if (i<this.listFiles.length)
        this.listFiles[i].downloading = downloading;
    },
    async download({ filename, label }) {
      this.setDownloading(filename);
      let response = await documentsService.get(this.data.guid,filename);
      let blobUrl = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = label;
      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );
      document.body.removeChild(link);
      this.setDownloading(filename,false);
    },
    async loadEnums() {
      for (let key in this.mapKeyToEnum) {
        let table = this.mapKeyToEnum[key];
        const list = (await enumsService.index(table)).data||[];
        let map = {};
        list.forEach(item=>{
          map[item.id] = item;
        })
        this.mapEnum[key] = map;
      }
    },
    onUpdateBescheid({value,idKategorie}) {
      laufzettelService.update("bescheid",{idKandidat:this.data.id,idKategorie,idBescheid:value});
    },
    async onUpdateKommentar({value,idKategorie}) {
      await laufzettelService.update("kommentar",{idKandidat:this.data.id,idKategorie,kommentar:value});
      this.loadLaufzettelData();
    },
    async setDone(done=true) {
      await laufzettelService.update("done",{idKandidat:this.data.id,done});
      await this.loadLaufzettelData();
      if (done)
        this.$router.push(this.$route.path.replace(/\d+\/?$/,""));
    },
    requestSetDone(done=true) {
      this.$confirm.require({
        acceptClass:"p-button-primary",
        acceptIcon:"pi pi-check text-xl",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Sicher?`,
        header: done ? 'Bearbeitung abschliessen' : 'Erneut bearbeiten',
        icon: 'pi pi-question-circle',
        accept: async () => {
          this.setDone(done);
        }
      });
    },
    onClickComment(e) {
      if (e && e.target) {
        if (e.target.matches("a")) {
          e.preventDefault();
          window.open(e.target.getAttribute("href"),"_blank");
        }
      }
    }
  },
}
</script>
