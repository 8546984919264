import http from "./http-auth";

class EnumsService {
  constructor(apiName="enums") {
    this.apiName=apiName;
  }
  index(key) {
    return http.get(`/${this.apiName}/${key}`);
  }
  update(key,id,data) {
    return http.put(`/${this.apiName}/${key}/${id}`,data);
  }
  create(key,data, config) {
    return http.post(`/${this.apiName}/${key}`, data, config);
  }
  delete(key,id) {
    return http.delete(`/${this.apiName}/${key}/${id}`);
  }
}
export default new EnumsService();
