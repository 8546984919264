<template>
  <div class="flex justify-content-center align-items-center flex-grow-1">
    <div class="px-2 lg:px-4 shadow-0 lg:shadow-2 border-black-alpha-30 m-0 md:m-3 lg:w-9 xl:w-6 bg-white-alpha-90">
      <div class="mb-5">
        <h2 class="mt-4">Hinweise zur Benutzung:</h2>
        <ol >
          <li>Wählen Sie in der Navigationsleiste den Menüpunkt "Laufzettel" aus</li>
          <li>Es wird eine Liste der zur Bearbeitung ausstehenden Dossiers präsentiert. Klicken Sie auf einen Eintrag, um die entsprechende
            Laufzettel-Ansicht zu öffnen.
          </li>
          <li>Sie befinden sich in der Laufzettel-Ansicht. Bearbeiten Sie das Dossier, indem Sie Ihre Spalte der Bewertungstabelle mit Ihren
            Bewertungen versehen und wo nötig mit Kommentaren (Sprechblasen-Symbol) ergänzen.
          </li>
          <li>Schliessen Sie die Bearbeitung des Dossiers ab, indem Sie in der Ansicht oben rechts auf "Abschliessen" klicken. Sie gelangen
            zur Dossier-Liste zurück, wo der Eintrag des abgeschlossenen Dossiers neu grün eingefärbt erscheint. Hinweis: Sie können
            den Abschluss eines Dossiers jederzeit widerrufen, indem Sie dieses erneut öffnen und auf "Bearbeiten" klicken (oben rechts).
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
ul>li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
ol { counter-reset: item; padding:0; line-height: 1.75;
  display: flex; flex-direction: column }
ol>li { display: flex; flex-direction: row }
ol>li:before {
  content: counter(item) ".\00a0\00a0";
  counter-increment: item;
  display: inline-block;
}
</style>
