import http from "./http-common";

export default class AuthService {
    async login(args, config) {
      let { data } = await http.post(`/auth/login`, args, config);
      return this.setLocalAuth(data)
    }
    async getProfile(config) {
      let { data } = await http.get(`/auth/profile`, config);
      return this.setLocalProfile(data);
    }
    async refresh(config) {
      let { data } = await http.get(`/auth/refresh`, config);
      return this.setLocalAuth(data)
    }
    async reset({email,url}, config) {
      return (await http.post(`/auth/reset`, { email,url }, config)).data||{};
    }
    async uuid(uuid, config) {
        return (await http.get(`/auth/reset/${uuid}`, config)).data||{};
    }
    async change({uuid,pass}, config) {
        return (await http.post(`/auth/reset/${uuid}`, { pass }, config)).data||{};
    }
    setLocal(key,data) {
      localStorage.setItem(key,JSON.stringify(data || null));
      return data;
    }
    getLocal(key) {
      let data = null;
      try {
        data = JSON.parse(localStorage.getItem(key));
      } catch (err) {
        data = null;
      }
      return data;
    }
    purgeLocal(key) {
      this.setLocal(key,null);
    }
    setLocalAuth(data) {
      return this.setLocal("auth",data);
    }
    getLocalAuth() {
      return this.getLocal("auth");
    }
    purgeLocalAuth() {
      this.purgeLocal("auth");
    }
    setLocalProfile(data) {
       this.setLocal("profile",data);
       dispatchEvent(new CustomEvent('auth-change', {}));
       return data;
    }
    getLocalProfile() {
      return this.getLocal("profile");
    }
    purgeLocalProfile() {
      this.purgeLocal("profile");
    }
    async ensure(f) {
      let localAuth = this.getLocalAuth();
      // if there is no accessToken present, return
      if (!localAuth)
        return false;
      //console.log("> local accessToken present")
      let data = null, retry = 0, error=false;
      while (retry++<2) {
        try {
          data = await f.call(this,localAuth);
          retry++;
          //console.log("> accessToken is valid", data);
        } catch (err) {
          // if the local accessToken is invalid, try refreshing it (once)
          //console.log("> accessToken invalid");
          error = err;
          try {
            localAuth = await this.refresh({
              headers:{
                'Authorization' : 'Bearer '+localAuth.refreshToken,
              }
            });
            this.setLocalAuth(localAuth)
            //console.log("> token refresh successful")
          } catch (err) {
            // if the refreshToken isn't valid, stop trying
            //console.log("> token refresh failed")
            this.purgeLocalAuth();
            retry++;
          }
        }
      }
      // if the endpoint threw a terminal error, rethrow it
      if (data===null && error!==false) {
        throw error;
      }
      return data;
    }
    async ensureAuth(userlevel=false){
      return await this.ensure( async (localAuth)=>{
        let profile = await this.getProfile({
          headers:{
            'Authorization' : 'Bearer '+localAuth.accessToken,
          }
        });
        if (userlevel!==false && (profile.level||-1)<userlevel)
          throw({message:"your userlevel is insufficient",messageTitle:"Unauthorized",code:403});
        return profile;
      });
    }
    async logout() {
        await this.ensure( async (localAuth)=>{
            await http.post(`/auth/logout`, {}, {
                headers:{
                    'Authorization' : 'Bearer '+localAuth.accessToken,
                }
            });
        });
        this.purgeLocalAuth();
        this.purgeLocalProfile();
    }
}
